<template>
  <div class="recommend" :class="isflex ? 'isflex' : ''">
    <div class="header">
      <div>热门作品</div>
    </div>
    <div class="list">
      <div
        class="item"
        :class="{ width: isflex }"
        v-for="(item, index) in wordList"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  name: "recommend",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    isflex: {},
  },
  data() {
    //这里存放数据
    return {
      url: this.$store.state.onlineBasePath,
      wordList: [
        "疫情防控微党课的细节是从哪里提现出的来1的的的的",
        "疫情防控微党课的细节是从哪里提现出的来1的的的的",
        "疫情防控微党课的细节是从哪里提现出的来1的的的的",
        "疫情防控微党课的细节是从哪里提现出的来1的的的的",
        "疫情防控微党课的细节是从哪里提现出的来1的的的的",
        "疫情防控微党课的细节是从哪里提现出的来1的的的的",
        "疫情防控微党课的细节是从哪里提现出的来1的的的的",
      ],
      title: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // if (this.$route.path == "/traceSource") {
    //   this.title = "溯源";
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.recommend {
  width: 100%;
  .header {
    margin-top: 35px;
    padding-left: 5px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    border-left: solid 5px #ff6900;
    .more {
      color: #999;
      font-size: 13px;
      cursor: pointer;
    }
  }
  .list {
    padding-top: 10px;
    width: 100%;
    .width {
      width: 25%;
    }
    .item {
      cursor: pointer;
      // padding: 10px 36px;
      margin: 20px 15px;
      box-sizing: border-box;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      .img {
        width: 120px;
        img {
          width: 120px;
          height: 80px;
        }
      }
      .info {
        margin-left: 5px;
        .title {
          line-height: 1.5;
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .dataInfo {
          box-sizing: border-box;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          padding-top: 10px;
          > div {
            display: flex;
            align-items: center;
            font-size: 13px;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

.isflex {
  width: 1338px;
  padding: 20px 20px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 20px;
  overflow-x: auto;

  .header {
    margin-top: 0;
  }

  .list {
    overflow-x: auto;
    display: flex;
  }
}
</style>
