<template>
  <div class="searchImage">
    <div class="box">
      <div class="left">
        <div class="main">
          <!-- 搜索框 -->
          <div class="search_box">
            <div class="search">
              <input
                class="inp"
                v-model="searchValue"
                type="text"
                placeholder="请输入关键搜索词"
                maxlength="75"
              />
              <div class="search_btn">
                <img
                  :src="require('assets/images/included_search.png')"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 分类 -->
          <div class="classfiy">
            <div
              class="class_item"
              :class="{ active: index == currentIndex }"
              v-for="(item, index) in classfiyList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="words">
            <div
              class="words_item"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <img :src="require('assets/images/word_sl.png')" alt="" />
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="option">
                  <span class="like">
                    <img :src="require('assets/images/dianzan.png')" alt="" />
                    <!-- <img
                      :src="require('assets/images/dianzan_active.png')"
                      alt=""
                    /> -->
                    {{ item.like }}
                  </span>
                  <span class="read">{{ item.readNum }} 阅读</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <recommend />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
import { getToken } from "utils/auth";
import { createAiLoading } from "utils/utils";
import recommend from "components/recommendWord/recommendWord.vue";
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "searchImage",
  components: {
    recommend,
    commonFooter,
  },
  data() {
    return {
      searchValue: "",
      page: 1,
      size: 10,
      currentIndex: 0,
      classfiyList: ["今日最新", "法律", "实用", "生态环境", "最热"],
      tableData: [
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
        {
          title: "班委会会议记录要范文",
          like: 31,
          readNum: 5021,
        },
      ],
    };
  },
  created() {},
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.commonFooter_box {
  margin-top: 20px;
  width: 100%;
}
.searchImage {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;
  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      width: 1036px;
      margin-right: 20px;
      background-color: #fff;
      display: flex;
      position: relative;
    }
    .right {
      background-color: #fff;
      flex: 1;
    }
  }
  .rankTips {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    z-index: 9999;
    font-size: 14px;
    color: #999;
    line-height: 25px;
    text-align: center;
  }

  .end {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #409eff;
    color: #fff;
    margin: 10px auto;
    font-size: 14px;

    border-radius: 5px;

    cursor: pointer;
  }

  .OfficeNav_box {
    position: relative;
    .time {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;

      .img {
        margin: 0 5px;
      }

      .text {
        color: #4587ff;
        font-weight: bold;
      }
    }
  }

  .viewImage {
    ::v-deep .el-dialog {
      width: 700px;
      .el-dialog__body {
        img {
          width: 100%;
        }
      }
    }
  }
  .aiImg_generate {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;

    .aiImg_item {
      position: relative;
      flex: 1;
      margin: 30px;
      img {
        width: 100%;
      }
      .option {
        padding: 0 0 0 20px;
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        height: 0px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s;
        background-color: rgba($color: #000000, $alpha: 0.7);
        overflow: hidden;
        opacity: 0;
        color: #409eff;
        .option_items {
          display: flex;
          align-items: center;
          > div {
            margin: 0 10px;
            display: flex;
            align-items: center;
          }
        }
        .name {
          color: #fff;
        }
        .view {
          // background: #409eff;
          color: #409eff;
        }
        .inset {
          // background: #409eff;
          color: #409eff;
        }
        > div {
          // background: #fff;
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
          }
          cursor: pointer;
          // width: 40px;
          // height: 40px;
          font-size: 14px;
          // text-align: center;
          // line-height: 40px;
          // border-radius: 50%;
        }
      }
      &:hover .option {
        height: 40px;
        opacity: 1;
      }
    }
  }
  .office-nav {
    @include flex-start;
    width: 100vw;
    // margin-left: -30px;
    .switch,
    .history {
      @include flex-start;
      display: inline-flex;
      margin-left: 30px;
      // margin-right: 20px;
      cursor: pointer;
      .txt {
        font-size: 13px;
        font-weight: 500;
        // color: #000;
        margin-left: 5px;
      }
    }
    .isActive {
      color: #409eff !important;
    }
    .ai {
      cursor: pointer;
      margin-left: 20px;
      font-size: 13px;
      display: flex;
      align-items: center;
      .txt {
        font-size: 13px;
        font-weight: 500;
        // color: #000;
        margin-left: 5px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .sidebar {
    background: #000;
    width: 190px;
    height: calc(100vh - 50px);
    overflow: auto;
    // position: fixed;

    ::v-deep .el-menu {
      .el-menu-item {
        height: 30px;
        line-height: 30px;
      }
      .is-active {
        border-right: 5px solid #409eff;
      }
    }
  }
  .noData {
    width: 0px;
    overflow: hidden;
    height: auto;
  }
  .main-no {
    margin-left: 190px;
  }
  .main {
    // flex: 1;
    width: 100%;
    height: 90vh;

    overflow: auto;
    .content {
      display: flex;
      .imgList {
        flex: 1;
      }
    }
    .search_box {
      padding: 20px 0;
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .time {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
        .text {
          font-size: 16px;
          font-weight: bold;
          color: #4587ff;
        }
      }
      .history {
        width: 40px;
        height: 40px;
        background: #4587ff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .search {
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-radius: 3px;
        box-shadow: 0 0 0 1px #ff6600;

        .search_left {
          .el-select {
            ::v-deep .el-input {
              .el-input__inner {
                width: 100px;
                border: 0px;
              }
            }
          }
        }

        .inp {
          height: 26px;
          border-left: 1px solid #e4e4e4;
          padding-left: 20px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-default;
          flex: 1;
        }
        .search_btn {
          // width: 40px;
          height: 40px;
          padding: 0 10px;
          background: #ff6600;
          // @include backgroundGroup("~assets/images/idiom.png");
          @include flex-center();
          // border-radius: 0 5px 5px 0;
          cursor: pointer;
          > img:first-child {
            width: 52px;
            height: 22px;
            margin: 0 10px;
          }
          img:last-child {
            width: 20px;
            height: 20px;
          }
          // .search_icon {
          //   width: 17px;
          //   height: 17px;
          //   @include backgroundGroup("~assets/images/idiom.jpg");
          // }
        }
      }

      .keyword_box {
        margin-top: 20px;
        display: flex;

        .keyword_item {
          font-size: 14px;
          color: #666666;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
    .words {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      .words_item {
        cursor: pointer;
        text-align: center;
        width: 25%;
        .info {
          .title {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            margin: 5px 0;
          }
          .option {
            margin: 5px auto;
            display: flex;
            width: 65%;

            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            .like {
              cursor: pointer;
              img {
                width: 14px;
                height: 14px;
              }
            }
            .read {
              color: gray;
            }
          }
        }
      }
    }
    .classfiy {
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-size: 14px;
      .active {
        background: #fffaf3;
        color: #ff6500;
      }
      > div {
        margin: 0 15px 0 0;
        padding: 7px 10px;
        background: #f4f4f4;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .kong {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #999;
      font-size: 14px;
      margin: 200px 0;
      img {
        width: 150px;
        height: 150px;
      }
      // height: 100%;
    }
    .tips {
      text-align: center;
      font-size: 14px;
      color: #999;
      font-family: PingFang SC;
      height: 30px;
      line-height: 30px;
    }
    .images {
      margin-top: 20px;
      padding: 0 20px;
      box-sizing: border-box;
      -webkit-column-count: 3; //想要排成的列数
      -webkit-column-gap: 4px;
      // display: flex;
      // position: relative;
      // flex-direction: column;
      // flex-wrap: wrap;
      // height: calc(100vh - 120px);
      .item {
        // width: 200px;
        // height: auto;
        // position: absolute;
        position: relative;
        // overflow: hidden;

        .backgord {
          width: 100%;
          height: 0%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba($color: #000000, $alpha: 0.6);
          // z-index: ;
          display: flex;
          align-items: center;
          justify-content: center;
          // display: none;
          -webkit-opacity: 0;
          transition: all 0.3s;
          .inset {
            background: #409eff;
            color: #fff;
          }
          .view {
            background: #409eff;
            color: #fff;
          }
          > div {
            cursor: pointer;
            margin: 0 3px;
            width: 50px;
            height: 50px;
            background: #fff;
            border-radius: 50%;
            line-height: 50px;
            text-align: center;
            // color: #999;
            font-size: 14px;
          }
        }
        img {
          width: 100%;
          margin: 1px 0;
          cursor: pointer;
        }
      }
      .item:hover .backgord {
        -webkit-opacity: 1;
        height: 70px;
      }
    }
  }
}
</style>
